package ui.screen.lesson

import arrow.core.left
import arrow.core.right
import arrow.core.toOption


public inline val ui.screen.lesson.LessonViewModel.LocalState.Companion.iso: arrow.optics.Iso<ui.screen.lesson.LessonViewModel.LocalState, arrow.core.Tuple5<kotlin.collections.Map<ivy.model.LessonItemId, kotlin.collections.Set<ivy.model.AnswerId>>, kotlin.collections.Map<ivy.model.LessonItemId, kotlin.String>, kotlin.collections.Map<ivy.model.LessonItemId, ivy.model.ChoiceOptionId>, kotlin.collections.Set<ivy.model.LessonItemId>, kotlin.collections.Set<ivy.model.LessonItemId>>> inline get() = arrow.optics.Iso(
  get = { localState: ui.screen.lesson.LessonViewModel.LocalState -> arrow.core.Tuple5(localState.`selectedAnswers`, localState.`openAnswersInput`, localState.`chosen`, localState.`answered`, localState.`completed`) },
  reverseGet = { tuple: arrow.core.Tuple5<kotlin.collections.Map<ivy.model.LessonItemId, kotlin.collections.Set<ivy.model.AnswerId>>, kotlin.collections.Map<ivy.model.LessonItemId, kotlin.String>, kotlin.collections.Map<ivy.model.LessonItemId, ivy.model.ChoiceOptionId>, kotlin.collections.Set<ivy.model.LessonItemId>, kotlin.collections.Set<ivy.model.LessonItemId>> -> ui.screen.lesson.LessonViewModel.LocalState(tuple.first, tuple.second, tuple.third, tuple.fourth, tuple.fifth) }
)

public inline val ui.screen.lesson.LessonViewModel.LocalState.Companion.selectedAnswers: arrow.optics.Lens<ui.screen.lesson.LessonViewModel.LocalState, kotlin.collections.Map<ivy.model.LessonItemId, kotlin.collections.Set<ivy.model.AnswerId>>> inline get() = arrow.optics.Lens(
  get = { localState: ui.screen.lesson.LessonViewModel.LocalState -> localState.`selectedAnswers` },
  set = { localState: ui.screen.lesson.LessonViewModel.LocalState, value: kotlin.collections.Map<ivy.model.LessonItemId, kotlin.collections.Set<ivy.model.AnswerId>> ->
  localState.copy(`selectedAnswers` = value)
}
)

public inline val ui.screen.lesson.LessonViewModel.LocalState.Companion.openAnswersInput: arrow.optics.Lens<ui.screen.lesson.LessonViewModel.LocalState, kotlin.collections.Map<ivy.model.LessonItemId, kotlin.String>> inline get() = arrow.optics.Lens(
  get = { localState: ui.screen.lesson.LessonViewModel.LocalState -> localState.`openAnswersInput` },
  set = { localState: ui.screen.lesson.LessonViewModel.LocalState, value: kotlin.collections.Map<ivy.model.LessonItemId, kotlin.String> ->
  localState.copy(`openAnswersInput` = value)
}
)

public inline val ui.screen.lesson.LessonViewModel.LocalState.Companion.chosen: arrow.optics.Lens<ui.screen.lesson.LessonViewModel.LocalState, kotlin.collections.Map<ivy.model.LessonItemId, ivy.model.ChoiceOptionId>> inline get() = arrow.optics.Lens(
  get = { localState: ui.screen.lesson.LessonViewModel.LocalState -> localState.`chosen` },
  set = { localState: ui.screen.lesson.LessonViewModel.LocalState, value: kotlin.collections.Map<ivy.model.LessonItemId, ivy.model.ChoiceOptionId> ->
  localState.copy(`chosen` = value)
}
)

public inline val ui.screen.lesson.LessonViewModel.LocalState.Companion.answered: arrow.optics.Lens<ui.screen.lesson.LessonViewModel.LocalState, kotlin.collections.Set<ivy.model.LessonItemId>> inline get() = arrow.optics.Lens(
  get = { localState: ui.screen.lesson.LessonViewModel.LocalState -> localState.`answered` },
  set = { localState: ui.screen.lesson.LessonViewModel.LocalState, value: kotlin.collections.Set<ivy.model.LessonItemId> ->
  localState.copy(`answered` = value)
}
)

public inline val ui.screen.lesson.LessonViewModel.LocalState.Companion.completed: arrow.optics.Lens<ui.screen.lesson.LessonViewModel.LocalState, kotlin.collections.Set<ivy.model.LessonItemId>> inline get() = arrow.optics.Lens(
  get = { localState: ui.screen.lesson.LessonViewModel.LocalState -> localState.`completed` },
  set = { localState: ui.screen.lesson.LessonViewModel.LocalState, value: kotlin.collections.Set<ivy.model.LessonItemId> ->
  localState.copy(`completed` = value)
}
)







public inline val <S> arrow.optics.Iso<S, ui.screen.lesson.LessonViewModel.LocalState>.selectedAnswers: arrow.optics.Lens<S, kotlin.collections.Map<ivy.model.LessonItemId, kotlin.collections.Set<ivy.model.AnswerId>>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.selectedAnswers
public inline val <S> arrow.optics.Lens<S, ui.screen.lesson.LessonViewModel.LocalState>.selectedAnswers: arrow.optics.Lens<S, kotlin.collections.Map<ivy.model.LessonItemId, kotlin.collections.Set<ivy.model.AnswerId>>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.selectedAnswers
public inline val <S> arrow.optics.Optional<S, ui.screen.lesson.LessonViewModel.LocalState>.selectedAnswers: arrow.optics.Optional<S, kotlin.collections.Map<ivy.model.LessonItemId, kotlin.collections.Set<ivy.model.AnswerId>>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.selectedAnswers
public inline val <S> arrow.optics.Prism<S, ui.screen.lesson.LessonViewModel.LocalState>.selectedAnswers: arrow.optics.Optional<S, kotlin.collections.Map<ivy.model.LessonItemId, kotlin.collections.Set<ivy.model.AnswerId>>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.selectedAnswers
public inline val <S> arrow.optics.Getter<S, ui.screen.lesson.LessonViewModel.LocalState>.selectedAnswers: arrow.optics.Getter<S, kotlin.collections.Map<ivy.model.LessonItemId, kotlin.collections.Set<ivy.model.AnswerId>>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.selectedAnswers
public inline val <S> arrow.optics.Setter<S, ui.screen.lesson.LessonViewModel.LocalState>.selectedAnswers: arrow.optics.Setter<S, kotlin.collections.Map<ivy.model.LessonItemId, kotlin.collections.Set<ivy.model.AnswerId>>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.selectedAnswers
public inline val <S> arrow.optics.Traversal<S, ui.screen.lesson.LessonViewModel.LocalState>.selectedAnswers: arrow.optics.Traversal<S, kotlin.collections.Map<ivy.model.LessonItemId, kotlin.collections.Set<ivy.model.AnswerId>>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.selectedAnswers
public inline val <S> arrow.optics.Fold<S, ui.screen.lesson.LessonViewModel.LocalState>.selectedAnswers: arrow.optics.Fold<S, kotlin.collections.Map<ivy.model.LessonItemId, kotlin.collections.Set<ivy.model.AnswerId>>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.selectedAnswers
public inline val <S> arrow.optics.Every<S, ui.screen.lesson.LessonViewModel.LocalState>.selectedAnswers: arrow.optics.Every<S, kotlin.collections.Map<ivy.model.LessonItemId, kotlin.collections.Set<ivy.model.AnswerId>>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.selectedAnswers

public inline val <S> arrow.optics.Iso<S, ui.screen.lesson.LessonViewModel.LocalState>.openAnswersInput: arrow.optics.Lens<S, kotlin.collections.Map<ivy.model.LessonItemId, kotlin.String>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.openAnswersInput
public inline val <S> arrow.optics.Lens<S, ui.screen.lesson.LessonViewModel.LocalState>.openAnswersInput: arrow.optics.Lens<S, kotlin.collections.Map<ivy.model.LessonItemId, kotlin.String>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.openAnswersInput
public inline val <S> arrow.optics.Optional<S, ui.screen.lesson.LessonViewModel.LocalState>.openAnswersInput: arrow.optics.Optional<S, kotlin.collections.Map<ivy.model.LessonItemId, kotlin.String>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.openAnswersInput
public inline val <S> arrow.optics.Prism<S, ui.screen.lesson.LessonViewModel.LocalState>.openAnswersInput: arrow.optics.Optional<S, kotlin.collections.Map<ivy.model.LessonItemId, kotlin.String>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.openAnswersInput
public inline val <S> arrow.optics.Getter<S, ui.screen.lesson.LessonViewModel.LocalState>.openAnswersInput: arrow.optics.Getter<S, kotlin.collections.Map<ivy.model.LessonItemId, kotlin.String>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.openAnswersInput
public inline val <S> arrow.optics.Setter<S, ui.screen.lesson.LessonViewModel.LocalState>.openAnswersInput: arrow.optics.Setter<S, kotlin.collections.Map<ivy.model.LessonItemId, kotlin.String>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.openAnswersInput
public inline val <S> arrow.optics.Traversal<S, ui.screen.lesson.LessonViewModel.LocalState>.openAnswersInput: arrow.optics.Traversal<S, kotlin.collections.Map<ivy.model.LessonItemId, kotlin.String>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.openAnswersInput
public inline val <S> arrow.optics.Fold<S, ui.screen.lesson.LessonViewModel.LocalState>.openAnswersInput: arrow.optics.Fold<S, kotlin.collections.Map<ivy.model.LessonItemId, kotlin.String>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.openAnswersInput
public inline val <S> arrow.optics.Every<S, ui.screen.lesson.LessonViewModel.LocalState>.openAnswersInput: arrow.optics.Every<S, kotlin.collections.Map<ivy.model.LessonItemId, kotlin.String>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.openAnswersInput

public inline val <S> arrow.optics.Iso<S, ui.screen.lesson.LessonViewModel.LocalState>.chosen: arrow.optics.Lens<S, kotlin.collections.Map<ivy.model.LessonItemId, ivy.model.ChoiceOptionId>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.chosen
public inline val <S> arrow.optics.Lens<S, ui.screen.lesson.LessonViewModel.LocalState>.chosen: arrow.optics.Lens<S, kotlin.collections.Map<ivy.model.LessonItemId, ivy.model.ChoiceOptionId>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.chosen
public inline val <S> arrow.optics.Optional<S, ui.screen.lesson.LessonViewModel.LocalState>.chosen: arrow.optics.Optional<S, kotlin.collections.Map<ivy.model.LessonItemId, ivy.model.ChoiceOptionId>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.chosen
public inline val <S> arrow.optics.Prism<S, ui.screen.lesson.LessonViewModel.LocalState>.chosen: arrow.optics.Optional<S, kotlin.collections.Map<ivy.model.LessonItemId, ivy.model.ChoiceOptionId>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.chosen
public inline val <S> arrow.optics.Getter<S, ui.screen.lesson.LessonViewModel.LocalState>.chosen: arrow.optics.Getter<S, kotlin.collections.Map<ivy.model.LessonItemId, ivy.model.ChoiceOptionId>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.chosen
public inline val <S> arrow.optics.Setter<S, ui.screen.lesson.LessonViewModel.LocalState>.chosen: arrow.optics.Setter<S, kotlin.collections.Map<ivy.model.LessonItemId, ivy.model.ChoiceOptionId>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.chosen
public inline val <S> arrow.optics.Traversal<S, ui.screen.lesson.LessonViewModel.LocalState>.chosen: arrow.optics.Traversal<S, kotlin.collections.Map<ivy.model.LessonItemId, ivy.model.ChoiceOptionId>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.chosen
public inline val <S> arrow.optics.Fold<S, ui.screen.lesson.LessonViewModel.LocalState>.chosen: arrow.optics.Fold<S, kotlin.collections.Map<ivy.model.LessonItemId, ivy.model.ChoiceOptionId>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.chosen
public inline val <S> arrow.optics.Every<S, ui.screen.lesson.LessonViewModel.LocalState>.chosen: arrow.optics.Every<S, kotlin.collections.Map<ivy.model.LessonItemId, ivy.model.ChoiceOptionId>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.chosen

public inline val <S> arrow.optics.Iso<S, ui.screen.lesson.LessonViewModel.LocalState>.answered: arrow.optics.Lens<S, kotlin.collections.Set<ivy.model.LessonItemId>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.answered
public inline val <S> arrow.optics.Lens<S, ui.screen.lesson.LessonViewModel.LocalState>.answered: arrow.optics.Lens<S, kotlin.collections.Set<ivy.model.LessonItemId>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.answered
public inline val <S> arrow.optics.Optional<S, ui.screen.lesson.LessonViewModel.LocalState>.answered: arrow.optics.Optional<S, kotlin.collections.Set<ivy.model.LessonItemId>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.answered
public inline val <S> arrow.optics.Prism<S, ui.screen.lesson.LessonViewModel.LocalState>.answered: arrow.optics.Optional<S, kotlin.collections.Set<ivy.model.LessonItemId>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.answered
public inline val <S> arrow.optics.Getter<S, ui.screen.lesson.LessonViewModel.LocalState>.answered: arrow.optics.Getter<S, kotlin.collections.Set<ivy.model.LessonItemId>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.answered
public inline val <S> arrow.optics.Setter<S, ui.screen.lesson.LessonViewModel.LocalState>.answered: arrow.optics.Setter<S, kotlin.collections.Set<ivy.model.LessonItemId>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.answered
public inline val <S> arrow.optics.Traversal<S, ui.screen.lesson.LessonViewModel.LocalState>.answered: arrow.optics.Traversal<S, kotlin.collections.Set<ivy.model.LessonItemId>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.answered
public inline val <S> arrow.optics.Fold<S, ui.screen.lesson.LessonViewModel.LocalState>.answered: arrow.optics.Fold<S, kotlin.collections.Set<ivy.model.LessonItemId>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.answered
public inline val <S> arrow.optics.Every<S, ui.screen.lesson.LessonViewModel.LocalState>.answered: arrow.optics.Every<S, kotlin.collections.Set<ivy.model.LessonItemId>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.answered

public inline val <S> arrow.optics.Iso<S, ui.screen.lesson.LessonViewModel.LocalState>.completed: arrow.optics.Lens<S, kotlin.collections.Set<ivy.model.LessonItemId>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.completed
public inline val <S> arrow.optics.Lens<S, ui.screen.lesson.LessonViewModel.LocalState>.completed: arrow.optics.Lens<S, kotlin.collections.Set<ivy.model.LessonItemId>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.completed
public inline val <S> arrow.optics.Optional<S, ui.screen.lesson.LessonViewModel.LocalState>.completed: arrow.optics.Optional<S, kotlin.collections.Set<ivy.model.LessonItemId>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.completed
public inline val <S> arrow.optics.Prism<S, ui.screen.lesson.LessonViewModel.LocalState>.completed: arrow.optics.Optional<S, kotlin.collections.Set<ivy.model.LessonItemId>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.completed
public inline val <S> arrow.optics.Getter<S, ui.screen.lesson.LessonViewModel.LocalState>.completed: arrow.optics.Getter<S, kotlin.collections.Set<ivy.model.LessonItemId>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.completed
public inline val <S> arrow.optics.Setter<S, ui.screen.lesson.LessonViewModel.LocalState>.completed: arrow.optics.Setter<S, kotlin.collections.Set<ivy.model.LessonItemId>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.completed
public inline val <S> arrow.optics.Traversal<S, ui.screen.lesson.LessonViewModel.LocalState>.completed: arrow.optics.Traversal<S, kotlin.collections.Set<ivy.model.LessonItemId>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.completed
public inline val <S> arrow.optics.Fold<S, ui.screen.lesson.LessonViewModel.LocalState>.completed: arrow.optics.Fold<S, kotlin.collections.Set<ivy.model.LessonItemId>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.completed
public inline val <S> arrow.optics.Every<S, ui.screen.lesson.LessonViewModel.LocalState>.completed: arrow.optics.Every<S, kotlin.collections.Set<ivy.model.LessonItemId>> inline get() = this + ui.screen.lesson.LessonViewModel.LocalState.completed
