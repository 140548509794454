@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package learn.composeapp.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainDrawable0 {
  public val compose_multiplatform: DrawableResource by 
      lazy { init_compose_multiplatform() }

  public val google_signin_logo: DrawableResource by 
      lazy { init_google_signin_logo() }
}

@InternalResourceApi
internal fun _collectCommonMainDrawable0Resources(map: MutableMap<String, DrawableResource>) {
  map.put("compose_multiplatform", CommonMainDrawable0.compose_multiplatform)
  map.put("google_signin_logo", CommonMainDrawable0.google_signin_logo)
}

internal val Res.drawable.compose_multiplatform: DrawableResource
  get() = CommonMainDrawable0.compose_multiplatform

private fun init_compose_multiplatform(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:compose_multiplatform",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/learn.composeapp.generated.resources/drawable/compose-multiplatform.xml", -1, -1),
    )
)

internal val Res.drawable.google_signin_logo: DrawableResource
  get() = CommonMainDrawable0.google_signin_logo

private fun init_google_signin_logo(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:google_signin_logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/learn.composeapp.generated.resources/drawable/google-signin-logo.xml", -1, -1),
    )
)
