@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package learn.composeapp.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.FontResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainFont0 {
  public val roboto_black: FontResource by 
      lazy { init_roboto_black() }

  public val roboto_black_italic: FontResource by 
      lazy { init_roboto_black_italic() }

  public val roboto_bold: FontResource by 
      lazy { init_roboto_bold() }

  public val roboto_bold_italic: FontResource by 
      lazy { init_roboto_bold_italic() }

  public val roboto_italic: FontResource by 
      lazy { init_roboto_italic() }

  public val roboto_light: FontResource by 
      lazy { init_roboto_light() }

  public val roboto_light_italic: FontResource by 
      lazy { init_roboto_light_italic() }

  public val roboto_medium: FontResource by 
      lazy { init_roboto_medium() }

  public val roboto_medium_italic: FontResource by 
      lazy { init_roboto_medium_italic() }

  public val roboto_regular: FontResource by 
      lazy { init_roboto_regular() }

  public val roboto_thin: FontResource by 
      lazy { init_roboto_thin() }

  public val roboto_thin_italic: FontResource by 
      lazy { init_roboto_thin_italic() }
}

@InternalResourceApi
internal fun _collectCommonMainFont0Resources(map: MutableMap<String, FontResource>) {
  map.put("roboto_black", CommonMainFont0.roboto_black)
  map.put("roboto_black_italic", CommonMainFont0.roboto_black_italic)
  map.put("roboto_bold", CommonMainFont0.roboto_bold)
  map.put("roboto_bold_italic", CommonMainFont0.roboto_bold_italic)
  map.put("roboto_italic", CommonMainFont0.roboto_italic)
  map.put("roboto_light", CommonMainFont0.roboto_light)
  map.put("roboto_light_italic", CommonMainFont0.roboto_light_italic)
  map.put("roboto_medium", CommonMainFont0.roboto_medium)
  map.put("roboto_medium_italic", CommonMainFont0.roboto_medium_italic)
  map.put("roboto_regular", CommonMainFont0.roboto_regular)
  map.put("roboto_thin", CommonMainFont0.roboto_thin)
  map.put("roboto_thin_italic", CommonMainFont0.roboto_thin_italic)
}

internal val Res.font.roboto_black: FontResource
  get() = CommonMainFont0.roboto_black

private fun init_roboto_black(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:roboto_black",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/learn.composeapp.generated.resources/font/roboto_black.ttf", -1, -1),
    )
)

internal val Res.font.roboto_black_italic: FontResource
  get() = CommonMainFont0.roboto_black_italic

private fun init_roboto_black_italic(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:roboto_black_italic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/learn.composeapp.generated.resources/font/roboto_black_italic.ttf", -1, -1),
    )
)

internal val Res.font.roboto_bold: FontResource
  get() = CommonMainFont0.roboto_bold

private fun init_roboto_bold(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:roboto_bold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/learn.composeapp.generated.resources/font/roboto_bold.ttf", -1, -1),
    )
)

internal val Res.font.roboto_bold_italic: FontResource
  get() = CommonMainFont0.roboto_bold_italic

private fun init_roboto_bold_italic(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:roboto_bold_italic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/learn.composeapp.generated.resources/font/roboto_bold_italic.ttf", -1, -1),
    )
)

internal val Res.font.roboto_italic: FontResource
  get() = CommonMainFont0.roboto_italic

private fun init_roboto_italic(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:roboto_italic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/learn.composeapp.generated.resources/font/roboto_italic.ttf", -1, -1),
    )
)

internal val Res.font.roboto_light: FontResource
  get() = CommonMainFont0.roboto_light

private fun init_roboto_light(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:roboto_light",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/learn.composeapp.generated.resources/font/roboto_light.ttf", -1, -1),
    )
)

internal val Res.font.roboto_light_italic: FontResource
  get() = CommonMainFont0.roboto_light_italic

private fun init_roboto_light_italic(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:roboto_light_italic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/learn.composeapp.generated.resources/font/roboto_light_italic.ttf", -1, -1),
    )
)

internal val Res.font.roboto_medium: FontResource
  get() = CommonMainFont0.roboto_medium

private fun init_roboto_medium(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:roboto_medium",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/learn.composeapp.generated.resources/font/roboto_medium.ttf", -1, -1),
    )
)

internal val Res.font.roboto_medium_italic: FontResource
  get() = CommonMainFont0.roboto_medium_italic

private fun init_roboto_medium_italic(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:roboto_medium_italic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/learn.composeapp.generated.resources/font/roboto_medium_italic.ttf", -1, -1),
    )
)

internal val Res.font.roboto_regular: FontResource
  get() = CommonMainFont0.roboto_regular

private fun init_roboto_regular(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:roboto_regular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/learn.composeapp.generated.resources/font/roboto_regular.ttf", -1, -1),
    )
)

internal val Res.font.roboto_thin: FontResource
  get() = CommonMainFont0.roboto_thin

private fun init_roboto_thin(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:roboto_thin",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/learn.composeapp.generated.resources/font/roboto_thin.ttf", -1, -1),
    )
)

internal val Res.font.roboto_thin_italic: FontResource
  get() = CommonMainFont0.roboto_thin_italic

private fun init_roboto_thin_italic(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:roboto_thin_italic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/learn.composeapp.generated.resources/font/roboto_thin_italic.ttf", -1, -1),
    )
)
